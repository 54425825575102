<template>
  <main
    v-if="notification"
    class="
      notification-toast
      fixed
      top-20
      right-10
      p-2
      w-80
      min-h-14
      border
      rounded-md
      flex
      items-center
      justify-start
      z-50
    "
  >
    <img
      :src="iconUrl"
      :alt="notification.message"
      class="w-12 notification-icon"
    />
    <span class="pl-2 text-md" v-html="notification.message"></span>
  </main>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iconUrl: '',
    }
  },
  mounted() {
    const temp = this.notification
    const node = document.querySelector('.notification-toast')

    if (temp && temp.type === 'info') {
      node.classList.add('border-blue-400')
      node.classList.add('bg-blue-100')
      node.classList.add('text-blue-800')
      this.iconUrl = this.getImgUrl('info-toast-icon.svg')
    }

    if (temp && temp.type === 'success') {
      node.classList.add('border-green-400')
      node.classList.add('bg-green-100')
      node.classList.add('text-green-800')
      this.iconUrl = this.getImgUrl('success-toast-icon.svg')
    }

    if (temp && temp.type === 'error') {
      node.classList.add('border-red-400')
      node.classList.add('bg-red-100')
      node.classList.add('text-red-800')
      this.iconUrl = this.getImgUrl('failure-toast-icon.svg')
    }

    setTimeout(() => {
      this.$emit('resetNotification')
    }, 7000)
  },
  methods: {
    getImgUrl(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
  },
}
</script>
